import React from "react";
import PropTypes from "prop-types";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import { Link } from "gatsby";
import LatticeBackgroundImage from "lattice/lib/components/LatticeBackgroundImage";
// import BuffyLogo from "./buffy-logo";

const ShortHighlight = ({ data, defaultImage }) => (
  <Link to={`/${data.slug}`}>
    {data.images ? (
      <LatticeBackgroundImage bgImageData={data.images[0].fluid} />
    ) : (
      <LatticeBackgroundImage bgImageData={defaultImage} />
    )}
    <div>
      <h2>{data.date}</h2>
      <h3>{data.title}</h3>
      {data.tags ? (
        <div className="tags">
          {data.tags.map(tag => (
            <div key={tag} className={`tag tag-${tag.toLowerCase()}`}>
              <span>{tag}</span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  </Link>
);

ShortHighlight.propTypes = {
  data: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
        }),
      }),
    ),
    date: PropTypes.string,
    title: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  defaultImage: PropTypes.shape({
    desktop: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ShortHighlight;
