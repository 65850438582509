import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/seo";
import ShortHighlight from "../components/short-highlight";
import "../styles/hp.scss";

const HomePage = ({ data }) => (
  <>
    <SEO title="Buffy" />
    <LatticeGrid
      className="hp-intro"
      fullWidth
      bgImageData={data.desktop.childImageSharp.fluid}
      style={{ backgroundPosition: "50% 23%" }}>
      <div />
      <h1>
        <pre>Black</pre> <pre>History</pre> <pre>Month</pre>
      </h1>
    </LatticeGrid>
    <LatticeGrid className="hp-highlights" innerGrid>
      <h1>Daily Highlights</h1>
      {data.highlights.edges.map(high => (
        <ShortHighlight
          data={high.node}
          defaultImage={data.desktop.childImageSharp.fluid}
          key={high.node.slug}
        />
      ))}
    </LatticeGrid>
  </>
);

HomePage.propTypes = {
  data: PropTypes.shape({
    highlights: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    desktop: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default HomePage;
export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "bhm-intro.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    highlights: allContentfulBlackHistoryMonthHighlight(
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          title
          tags
          date(formatString: "MMMM Do")
          slug
          images {
            fluid(maxWidth: 300) {
              src
              srcSet
              aspectRatio
              srcWebp
              base64
            }
          }
        }
      }
    }
  }
`;
